import React from "react";
import Hero from "../components/Hero";

const StandhouderInschrijen = () => {
    return (
        <div className="container-deelname">
            <Hero heroTitle={"Inschrijven standhouder"} buttonOff={true}/>
            <div className="header">
                <h2>Inschrijven voor standbemanning</h2>
                <p>Hieronder kunt u de standbemanning inschrijven. Per beursstand zijn twee tickets inbegrepen. De hele dag is er gratis catering inbegrepen.<br /><br />
                    U heeft een e-mail gekregen met uw toegangscode. Vul deze code hieronder in. Per code kunt twee tickets halen. 
                    Let op: eenmaal de code gebruikt is deze niet meer geldig. Ook niet als u maar 1 ticket heeft gehaald<br /><br />
                    Wilt u meer standbemanning aanmelden? Dan kunt u bij ons extra tickets bijbestellen. Mail naar info@waterinfodag.nl. Dit kan op factuur.
                    </p>
            </div>
            <iframe src="https://shop.tickets.cm.com/fb763499-b9e3-4f06-93d9-e42232812a7a/presales/MTQ5MjQ4"></iframe>
        </div>
    )
}

export default StandhouderInschrijen;
import React, { useState } from "react";
import { handleSubmit } from '../utils/formUtils';

const NieuwsbriefAanmelden = () => {
    const [newsletterFormData, setNewsletterFormData] = useState({
        email: "",
        voornaam: ""
    });

    const [dataSent, setDataSent] = useState(false);
    

    const handleInputChange = (e) => {
        console.log("open change");
        const { name, value } = e.target;
        setNewsletterFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="newsletter" id="newsletter">
            <h2>Inschrijven Nieuwsbrief</h2>
            <form onSubmit={(e) => handleSubmit(e, newsletterFormData, "https://waterinfodag.nl/api/newsletter", setNewsletterFormData, setDataSent)}>
                <label htmlFor="email">E-mailadres</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={newsletterFormData.email}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="voornaam">Voornaam</label>
                <input
                    type="text"
                    name="voornaam"
                    id="voornaam"
                    value={newsletterFormData.voornaam}
                    onChange={handleInputChange}
                />
                <input type="submit" />
            </form>
            {
                dataSent && (<p className="dataSent">Bedankt voor de aanmelding. We houden u op de hoogte van het laatste nieuws.</p>)
            }
        </div>
    )
};

export default NieuwsbriefAanmelden;
import React from 'react';
import LinkedInLogo from "../assets/linkedin-logo.svg";

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-container-sub">
                    <h3>Organisatie</h3>
                    <p>Waterinfodag wordt georganiseerd door <a href="https://sense-events.nl/" target="_blank">Sense Events</a></p>
                    <p><strong>T:</strong> 06 34 73 73 22 / 06 53 29 77 41</p>
                </div>
                <div>
                    <h3>Volg ons</h3>   
                    <div className="footer-social-logo-container">
                        <a href="https://www.linkedin.com/company/waterinfodag" target="_blank"><img src={LinkedInLogo} className="footer-social-logo" /></a>
                    </div>                
                </div>
            </div>
        </footer>
    )
}

export default Footer;
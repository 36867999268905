import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import PresentatieCard from "../components/PresentatieCard";

import { Link } from "react-router-dom";

const testData =
    [
        {
            "session_id": 456,
            "title": "Harnessing the Value of Data for Sustainable Wastewater Treatment: Addressing Organic Micropollutant Removal and Nitrous Oxide Emissions",
            "summary": "De nieuwe opzet van de inwinning van het AHN en Beeldmateriaal wordt toegelicht...",
            "description": "AHN is sinds 1997 ingewonnen, Beeldmateriaal sinds 2012. Beide datasets zijn inmiddels als open data beschikbaar en worden voor tal van toepassingen gebruikt. Vanaf 2025 worden beide datasets gecombineerd ingewonnen. Ook in de distributie van de data is veel veranderd. In het begin ging dit middels stapels harde schijven. Momenteel gaat dat meer en meer via de cloud. Wat zijn de recente veranderingen en wat verwacht de gebruiker van deze data van ons? Dat wordt in deze presentatie toegelicht.",
            "target_audience": "Informatiesector, Gebruikers",
            "topic": "Een betrouwbare basis",
            "speakers": [
                {
                    "name": "Jeroen Leusink",
                    "company": "het Waterschapshuis"
                },
                {
                    "name": "Adriaan van Natijne",
                    "company": "het Waterschapshuis"
                },
                {
                    "name": "Guus Huls",
                    "company": "Het Waterschapshuis"
                },
                {
                    "name": "Dick Konings",
                    "company": "Het Waterschapshuis"
                },
                {
                    "name": "Matthijs Bierman",
                    "company": "TriOpSys"
                }
            ]
        },
    ];

const Programma = (props) => {
    const [programma, setProgramma] = useState(testData);               // Originele data
    const [filteredProgramma, setFilteredProgramma] = useState([]);     // Gefilterde data
    const [filterTime, setFilterTime] = useState('');                   // Tijd-filter
    const [filterTopic, setFilterTopic] = useState('');                 // Onderwerp-filter  

    const timeFilterOptions = ["10.00 - 11.00 uur", "11.30 - 12.15 uur", "13.00 - 13.45 uur", "14.15 - 15.00 uur", "15.30 - 16.15 uur"]

    useEffect(() => {
        const fetchProgram = async () => {
            try {
                const response = await fetch('https://waterinfodag.nl/api/programma');
                const data = await response.json();
                setProgramma(data);
                setFilteredProgramma(data);  // Standaard de volledige data tonen
            } catch (error) {
                console.error('Error fetching program:', error);
            }
        };
        fetchProgram();
    }, []);

    // Functie om de filters toe te passen
    const applyFilters = () => {
        let filtered = programma;

        if (filterTime) {
            const [startFilter, endFilter] = filterTime
                .replace(" uur", "")
                .split(" - ")
                .map(timeToMinutes);



            filtered = filtered.filter((sessie) => {
                if (!sessie.session_time) return false;
                console.log(`Sessie tijd: ${sessie.session_time}`);

                const [startSession, endSession] = sessie.session_time
                    .replace(" uur", "")
                    .split(" - ")
                    .map(timeToMinutes);

                console.log(`startFilter: ${startFilter}`);
                console.log(`endFilter: ${endFilter}`);
                console.log(`startSession: ${startSession}`);
                console.log(`endSession: ${endSession}`);

                // Controleer of er overlap is tussen sessie en het geselecteerde tijdsblok
                return startSession < endFilter && endSession > startFilter;
            });
        }

        if (filterTopic) {
            filtered = filtered.filter((sessie) => sessie.topic.includes(filterTopic));
        }

        setFilteredProgramma(filtered);
    };

    // Hulpfunctie om tijd om te zetten naar minuten voor correcte vergelijking
    const timeToMinutes = (time) => {
        const [hours, minutes] = time.split(".").map(Number);
        return hours * 60 + (minutes || 0);
    };

    useEffect(() => {
        applyFilters();
    }, [filterTime, filterTopic]);  // Filters opnieuw toepassen bij wijziging

    return (
        <div className="container-programma">
            <Hero heroTitle={"Programma"} />
            <div className="header_info1">
                <h2>Laat data stromen als water</h2>
                <p>Hieronder vinst u het programma van de vorige editie op 27 maart 2025.
                </p>
            </div>

            {/* Filtersectie */}
            <div className="filter">
                <div>
                    <h3>Filter hier op tijdstip</h3>
                    {timeFilterOptions.map((filter) => (
                        <button
                            key={filter}
                            onClick={() => setFilterTime(filter === filterTime ? "" : filter)}
                            style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#263064",
                                backgroundColor: filterTime === filter ? "white" : "#263064",
                                color: filterTime === filter ? "#263064" : "white",
                            }}
                        >
                            {filter}
                        </button>
                    ))}
                </div>
            </div>

            {/* Renderen van gefilterde PresentatieCards */}
            <div className="cointainer-card">
                {filteredProgramma.length > 0 ? (
                    filteredProgramma.map((sessie) => (
                        <PresentatieCard key={sessie.session_id} sessie={sessie} />
                    ))
                ) : (
                    <p>Geen presentaties gevonden voor de geselecteerde filters.</p>
                )}
            </div>
        </div>
    )
}

export default Programma;
import React from "react";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";

const Deelname = () => {
    return (
        <div className="container-deelname">
            <Hero heroTitle={"Deelname"} />
            <div className="header">
                <h2>Inschrijven voor deelname</h2>
                <p>De volgende Waterinfodag vindt plaats op 19 maart 2026. Schrijf u in voor <Link to="/#newsletter">onze nieuwsbrief</Link> om als eerste
                    op de hoogte te zijn wanneer u zich weer kunt inschrijven.
                </p>
                {/* 
                <p>Hieronder kunt u zich inschrijven voor de volgende Waterinfodag. De hele dag is er gratis catering inbegrepen.<br /><br />
                    Medewerkers van waterschappen, Rijkswaterstaat en provincies kunnen zich gratis aanmelden. Dit is mogelijk gemaakt door het Waterschapshuis, Rijkswaterstaat, Informatiehuis Water en STOWA.<br /><br />
                    Een bezoeker ticket kost € 150,- excl. btw. U kunt dit hieronder direct betalen, of via factuur betalen. Mail naar info@waterinfodag.nl om een factuur aan te vragen. Komt u met 4 of meer collega's? Dan krijgt u 50% organisatie korting.<br /><br />
                    Studentendeelname is vrij door sponsoring van Rijkswaterstaat, het Waterschapshuis, STOWA, MUG Ingenieursbureau en KWR.
                    </p>
                    */}
            </div>
        </div>
    )
}

export default Deelname;
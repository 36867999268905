import React from 'react';
import { Link } from 'react-router-dom';

const Hero = ({heroTitle, buttonOff}) => {

return (
    <header className="hero">
        <h1>{heroTitle}</h1>
        <h2>19 maart 2026 | Congrescentrum 1931 &#39;s-Hertogenbosch</h2>
        <h2>09.00 - 17.00 uur</h2>
        {!buttonOff && (<Link to="/#newsletter" className="button">Inschrijven voor nieuwsbrief</Link>)}
    </header>
)
}

export default Hero;
import React, { useState } from "react";
import Hero from "../components/Hero";
import VideoStream from "../components/VideoStream";

const Live = () => {
    const [waarderingData, setWaarderingData] = useState({
        waardering: "",
        opmerking: ""
    });


    const [sendSucces, SetSendSucces] = useState(false);

    const handleInputChange = (e) => {
        console.log("open change");
        const { name, value} = e.target;
        setWaarderingData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://waterinfodag.nl/api/evaluatie", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(waarderingData)
        }).then((response) => {
            console.log("fetch call gedaan");
            console.log(response);
            if (response.ok) {
                SetSendSucces(true);
            }
        });
    }

    return (
        <div className="live__container">

            <Hero heroTitle={"Livestream"} />

            <div className="live__content">
                <h2>Livesteam donderdag 27 maart.</h2>

                <p>Hier worden de presentaties vanuit het Streamtheater live uitgezonden. Kijk hieronder mee met de presentaties.
                </p>
                <VideoStream />
            </div>
            {/* 
            <div className="live__evaluatie">
                <h2>Wat vond u van de livestream?</h2>
                <form onSubmit={handleSubmit}>
                    <label for="waardering">Wat vond u van de livestream?</label>
                    <select name="waardering" value={waarderingData.waardering} onChange={handleInputChange}>
                        <option value="Heel goed">Heel goed</option>
                        <option value="Goed">Goed</option>
                        <option value="Gemiddeld">Gemiddeld</option>
                        <option value="Minder goed">Minder goed</option>
                        <option value="Niet goed">Niet goed</option>
                    </select>
                    <label for="opmerking">Opmerking</label>
                    <input type="text" id="opmerking" name="opmerking" value={waarderingData.opmerking} onChange={handleInputChange} />
                    <input type="submit" />
                </form>
                {sendSucces && <p>Bedankt voor uw evaluatie.</p>}
            </div>
            */}
        </div>
    )
}

export default Live;
import React, { useState, useRef, useEffect } from "react"
import Hero from "../components/Hero";
import { handleSubmit } from '../utils/formUtils';

const Streamtheater = () => {
    const [presentation, setPresentation] = useState({
        speakers: [],
        title: "",
        description: "",
        summary: "",
        comment: "",
        timeslot: "",
    });

    const formRef = useRef(null);
    const [dataSent, setDataSent] = useState(false);
    const [errorWithSent, setErrorWithSent] = useState(false);

    const [timeslots, setTimeslots] = useState([]);

    useEffect(() => {
        // Haal de beschikbare tijdsloten op via de API
        fetch('https://waterinfodag.nl/api/stream-timeslots')
            .then((response) => response.json())
            .then((data) => setTimeslots(data))
            .catch((error) => console.error('Error fetching timeslots:', error));
    }, []);


    const handleSubmitAddOn = (event) => {
        event.preventDefault();

        const form = event.target;
        let isValid = true;

        // Controleer elk vereist veld handmatig en extra controle voor andere velden.
        const requiredFields = form.querySelectorAll("[required]");
        requiredFields.forEach((field) => {
            if (!field.value.trim()) {
                field.setCustomValidity("Dit veld is verplicht.");
                isValid = false;

                // Voeg visuele feedback toe
                field.classList.add("error");
            } else {
                field.setCustomValidity("");
                field.classList.remove("error");
            }
        });

        if (presentation.speakers.length === 0) {
            alert("Voeg een spreker toe.");
            isValid = false;
        }

        if (isValid) {
            handleSubmit(event, presentation, "https://waterinfodag.nl/api/streamtheater", setPresentation, setDataSent, setErrorWithSent);
        } else {
            // Scroll naar het eerste foutieve veld
            form.querySelector(".error")?.focus();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPresentation((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSpeakerChange = (index, e) => {
        const { name, value } = e.target;
        const newSpeakers = [...presentation.speakers];
        newSpeakers[index] = {
            ...newSpeakers[index],
            [name]: value,
        };
        setPresentation((prevState) => ({
            ...prevState,
            speakers: newSpeakers,
        }));
    };

    const addSpeaker = () => {
        setPresentation((prevState) => ({
            ...prevState,
            speakers: [
                ...prevState.speakers,
                {
                    name: "",
                    company: "",
                    email: "",
                    phone: "",
                },
            ],
        }));
    };

    const removeSpeaker = (index) => {
        setPresentation((prevState) => ({
            ...prevState,
            speakers: prevState.speakers.filter((_, i) => i !== index),
        }));
    };

    return (
        <div className="voorstel__container">
            <Hero heroTitle="Streamtheater reserveren" />
            <div className="voorstel__header">
            </div>

            <div className="voorstel__form">
                <h2>Streamtheater presentaties</h2>
                <p>Naast het reguliere programma, zijn er ook presentaties in het Streamtheater. Dit is een amfitheater op de beursvloer waar de presentaties op de dag zelf te volgen zijn.
                    Hier vinden presentaties plaats over nieuwe ontwikkelingen, producten of interessante inzichten. Tijdsloten zijn 20 minunten.
                </p>
                <p>Het is mogelijk om een tijdslot te boeken. Zolang het onderwerp een relatie heeft met de watersector zijn de tijdsloten vrij in te vullen. Voor plaatsing vindt er geen selectie door een programmacommissie plaats.
                    De presentaties zijn op de locatie te bezoeken, worden live uitgezonden via onze website en worden opgenomen. Na het evenement ontvangt u de opname. Kosten per tijdslot zijn € 225,- excl. btw.
                </p>

                <p>Het Streamtheater is vol. Op dit moment zijn er geen beschikbare tijdsloten meer. Heeft u wel interesse? Selecteer bij gewenste tijdslot de optie 'wachtlijst'. Dan houden we u op de hoogte wanneer er een plek vrij komt.</p>
                
                <h3>Reserveren</h3>
                <form ref={formRef} onSubmit={handleSubmitAddOn}>

                    {/* Knop om een nieuwe spreker toe te voegen */}
                    <button type="button" onClick={addSpeaker}>Voeg een spreker toe</button>

                    {/* Sprekers formuliervelden */}
                    {presentation.speakers.map((speaker, index) => (
                        <div key={index} className="speaker-fields">
                            <h4>Spreker {index + 1}</h4>
                            <label htmlFor={`speaker-name-${index}`}>Naam</label>
                            <input
                                type="text"
                                name="name"
                                id={`speaker-name-${index}`}
                                value={speaker.name}
                                onChange={(e) => handleSpeakerChange(index, e)}
                                required
                            />
                            <label htmlFor={`speaker-company-${index}`}>Organisatie</label>
                            <input
                                type="text"
                                name="company"
                                id={`speaker-company-${index}`}
                                value={speaker.company}
                                onChange={(e) => handleSpeakerChange(index, e)}
                                required
                            />
                            <label htmlFor={`speaker-email-${index}`}>E-mail</label>
                            <input
                                type="email"
                                name="email"
                                id={`speaker-email-${index}`}
                                value={speaker.email}
                                onChange={(e) => handleSpeakerChange(index, e)}
                                rquired
                            />
                            <label htmlFor={`speaker-phone-${index}`}>Telefoonnummer</label>
                            <input
                                type="text"
                                name="phone"
                                id={`speaker-phone-${index}`}
                                value={speaker.phone}
                                onChange={(e) => handleSpeakerChange(index, e)}
                            />
                            <button type="button" className="voostel__btn_remove" onClick={() => removeSpeaker(index)}>Verwijder spreker {index + 1}</button>
                            {/* Knop om een nieuwe spreker toe te voegen */}
                            <div >
                                <button type="button" onClick={addSpeaker}>Voeg spreker toe</button>
                            </div>
                        </div>
                    ))}

                    <label htmlFor="title">Presentatie titel</label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        value={presentation.title}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="description">Beschrijving</label>
                    <textarea
                        name="description"
                        id="description"
                        value={presentation.description}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="summary">Samenvatting</label>
                    <input
                        type="text"
                        name="summary"
                        id="summary"
                        value={presentation.summary}
                        onChange={handleInputChange}
                        required
                    />

                    <label>Gewenste tijdslot:</label>
                    <select name="timeslot" value={presentation.timeslot || ""} onChange={handleInputChange}>
                        <option value="" disabled>Selecteer een tijdslot</option> {/* Zorg ervoor dat de eerste optie niet geselecteerd wordt */}
                        {timeslots.map((timeslot, index) => (
                            <option key={index} value={`${timeslot.timeslot}`}>
                                {timeslot.timeslot}
                            </option>
                        ))}
                    </select>

                    <hr color="rgb(226, 226, 226)" />
                    <label htmlFor="comment">Opmerking</label>


                    <input
                        type="text"
                        name="comment"
                        id="comment"
                        value={presentation.comment}
                        onChange={handleInputChange}
                    />

                    <input type="submit" />
                </form>
                {
                    dataSent && (<p className="dataSent">Bedankt voor de reservering. Het tijdslot is voor u gereserveerd. We nemen contact op bij verdere informatie.</p>)
                }

                {
                    errorWithSent && (<p className="dataNotSent">Helaas is er iets mis gegaan tijdens het verzenden. Heeft u alles goed ingevuld? Probeer het nog een keer of mail ons op info@waterinfodag.nl</p>)
                }

            </div>
        </div>
    )
}

export default Streamtheater;
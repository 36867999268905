export const handleSubmit = async (e, formData, actionUrl, setFormData, setDataSent, setErrorWithSent) => {
    e.preventDefault();
    try {
        const response = await fetch(actionUrl, {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(formData)
        });

        if (!response.ok) {
            setErrorWithSent(true);
            throw new Error("Er is een probleem met het verzenden van je gegevens.");
        }

        // Reset formData na succesvolle verzending
        setFormData(() => {
            const resetData = {};
            Object.keys(formData).forEach(key => {
                if (key === "speakers") {
                    resetData[key] = [];  // Zet 'speakers' op een lege array
                } else if (key === "topic" || key === "target_audience") {
                    resetData[key] = [];  // Zet checkbox groepen op een lege array
                } else if (key === "presentation_form") {
                    resetData[key] = '';  // Zet de radiobutton op een lege string (geen selectie)
                } else {
                    resetData[key] = '';  // Leeg andere velden
                }
            });
            return resetData;
        });

        setDataSent(true);
    } catch (error) {
        setErrorWithSent(true);
        console.error(error.message);
        // Toon een foutmelding aan de gebruiker
    }
};
import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import WinkelCard from "../components/WinkelCard";
import WinkelCart from "../components/WinkelCart";
import { handleSubmit } from '../utils/formUtils';

const Winkel = (props) => {
    const [cart, setCart] = useState([]);
    const [contact, setContact] = useState({
        naam: "",
        email: "",
        organisatie: "",
        opmerking: ""
    })

    const [products, setProducts] = useState([]);

    const [send, setSend] = useState(false);
    const [cartEmpty, setCartEmpty] = useState(false);

    const [isOverlayVisible, setOverlayVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetch('https://waterinfodag.nl/api/producten');
                const data = await result.json();
                setProducts(data);
            } catch (err) {
                console.log(err.message);
            }
        };
        fetchData();
    }, []);

    const toggleOverlay = () => {
        setOverlayVisible(!isOverlayVisible);
    };

    const addToCart = (product, quantity) => {
        setCart((prevCart) => {
            const existingProduct = prevCart.find(item => item.id === product.id);
            if (existingProduct) {
                return prevCart.map(item =>
                    item.id === product.id
                        ? { ...item, quantity: item.quantity + Number([quantity]) }
                        : item
                );
            } else {
                return [...prevCart, { ...product, quantity: Number([quantity]) }];
            }

        })
    };

    const updateQuantity = (product, amount) => {
        const updatedCart = cart.map(item =>
            item.id === product.id ?
                { ...item, quantity: item.quantity + amount } :
                item
        ).filter(item => item.quantity > 0);
        setCart(updatedCart);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContact((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const sendCart = (e) => {
        e.preventDefault();

        if (cart.length === 0) {
            setCartEmpty(true);
            return;
        }

        const sendData = JSON.stringify({ cart: cart, contact: contact })
        fetch("https://waterinfodag.nl/api/cart", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: sendData
        }).then((response) => {
            if (response.ok) {
                setCart([]);
                setContact({
                    naam: "",
                    email: "",
                    organisatie: "",
                    opmerking: ""
                });
                setSend(true);
            }
        });
    }

    return (
        <div className="winkel__container">
            <Hero heroTitle="Winkel" />
            <div className="winkel__header">
                <h2>Beurswinkel</h2>
                <p>Het is niet meer mogelijk om stnadmateriaal te bestellen. Toch nog een wens? Neem contact met ons op via info@waterinfodag.nl en wij kijken naar de mogelijkheden.</p>
            </div>
            {/* 
            <div className="winkel__btn__cart">
                <a href="#bestellijst"><button id="orderForm_btn">Bestellijst</button></a>
            </div>
            */}
            {/*
            <div className="winkel__content-container">
                {
                    products.map(product => (
                            <WinkelCard
                                key={product.id}
                                product={product}
                                addToCart={addToCart}
                                buttonText="Toevoegen"
                            />
                    ))
                }
            </div>
            */}
            {/* 
            <div id="bestellijst" className="winkel__cart">
                <h2>Bestelling</h2>
                <WinkelCart cart={cart} updateQuantity={updateQuantity} />

                <h3>Contact gegevens</h3>
                <form className="winkel__cart__form" onSubmit={sendCart}>
                    <label for="naam">Naam</label>
                    <input
                        type="text"
                        name="naam"
                        id="naam"
                        value={contact.naam}
                        onChange={handleInputChange}
                        required
                    />
                    <label for="email">E-mailadres</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={contact.email}
                        onChange={handleInputChange}
                        required
                    />
                    <label for="organisatie">Organisatie</label>
                    <input
                        type="text"
                        name="organisatie"
                        id="organisatie"
                        value={contact.organisatie}
                        onChange={handleInputChange}
                        required
                    />
                    <label for="opmerking">Bericht</label>
                    <textarea
                        name="opmerking"
                        id="opmerking"
                        value={contact.opmerking}
                        onChange={handleInputChange}
                    />
                    <input id="cart__submit" type="submit" />
                </form>
                {
                    cartEmpty && (<p className="winkel__cart__empty">Uw winkelwagen is leeg. Voeg producten toe om te bestellen.</p>)
                }
                {
                    send && (<p className="winkel__cart__succes">Uw bestelling is verzonden, bedankt. We nemen zo snel mogelijk contact met u op.</p>)
                }
            </div>
            */}
        </div >
    );
}

export default Winkel;
import React, { useState, useEffect } from "react";
import Hero from "../components/Hero";
import Map2D from "../assets/Waterinfodag-Plattegrond-2025.webp";
import { Link } from "react-router-dom";

import { ReactComponent as MapSVG } from "../assets/Waterinfodag-Plattegrond-2026.svg";

const Beurs = () => {
    const [map, setMap] = useState(Map2D);

    const toggle2DMap = () => {
        setMap(Map2D);
    };



    return (
        <div className="container-beurs">
            <Hero heroTitle={"Beurs"} />
            <h2 className="header_info1">Plattegrond</h2>
            <p className="info_beurs">Hieronder ziet u de beursplattegrond voor 19 maart 2026. De standnummers waar nog geen naam achter staat
                zijn nog beschikbaar. <Link to="/reserveren-beursplaats">Reserveer hier</Link> een beursplaats.<br /><br />
                De plattegrond kan nog veranderen om beter aan te sluiten op de behoeften.</p>
            {/* 
            <div className="buttonMap">
                <button onClick={toggle2DMap} value="Map2D">2D</button>
                <button onClick={toggle3DMap} value="Map3D">3D</button>
            </div>
            */}
            <MapSVG className="map" />

            {/* <img src={MapSVG} className="map" /> */}
        </div>
    )
}

export default Beurs;
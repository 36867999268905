import React from "react";
import hWh from "../assets/logo-hwh-website.webp";
import rws from "../assets/logo-rws-website.webp";
import stowa from "../assets/logo-stowa-website.webp";
import ihw from "../assets/logo-ihw-website.webp";

const Partners = () => {
    return (
        <div className="partners">
            <h2>Partners</h2>
            <div className="partners-logo">
                <img src={hWh}></img>
                <img src={rws}></img>
                <img src={ihw}></img>
                <img src={stowa}></img>
            </div>
        </div>
    )
};

export default Partners;
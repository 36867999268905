import React, { useState, useEffect } from "react"
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import { handleSubmit } from '../utils/formUtils';

const Reserveren = () => {
    const [beursFormData, setBeursFormData] = useState({
        naam: "",
        email: "",
        telefoon: "",
        organisatie: "",
        standnummer: ""
    });

    const [dataSent, setDataSent] = useState(false);
    const [availableBooths, setAvailableBooths] = useState([]);

    useEffect(() => {
        // Haal de beschikbare tijdsloten op via de API
        fetch('https://waterinfodag.nl/api/beschikbare-standnummers')
            .then((response) => response.json())
            .then((data) => setAvailableBooths(data))
            .catch((error) => console.error('Error fetching timeslots:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBeursFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="reserveren__container">
            <Hero heroTitle="Beursplaats reserveren" />
            <div className="reserveren__header">
                <h2 >Reserveer een standplaats voor 2026</h2>
                <p>Hieronder kunt u een standplaats reserveren voor de volgende editie op 19 maart. Informatie en prijzen van de standplaatsen vindt u <Link to="/prijzen-beurs">hier.</Link><br /><br />
                </p>
            </div>

            <div className="reserveren__form">
                <form onSubmit={(e) => handleSubmit(e, beursFormData, "https://waterinfodag.nl/api/beursplaats", setBeursFormData, setDataSent)}>
                    <label htmlFor="naam">Naam</label>
                    <input
                        type="text"
                        name="naam"
                        id="naam"
                        value={beursFormData.naam}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="email">E-mailadres</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={beursFormData.email}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="telefoon">Telefoonnummer</label>
                    <input
                        type="tel"
                        name="telefoon"
                        id="telefoon"
                        value={beursFormData.telefoon}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="organisatie">Organisatie</label>
                    <input
                        type="text"
                        name="organisatie"
                        id="organisatie"
                        value={beursFormData.organisatie}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="standnummer">Gewenste standnummer:</label>

                    <input
                        type="text"
                        name="standnummer"
                        id="standnummer"
                        value={beursFormData.standnummer}
                        onChange={handleInputChange}
                        required
                    />

                    {/* 

                    <select name="standnummer" value={beursFormData.standnummer || ""} onChange={handleInputChange}>
                        <option value="" disabled>Selecteer een standnummer</option>*/} {/* Zorg ervoor dat de eerste optie niet geselecteerd wordt */}
                        {/* 
                        {availableBooths.map((boothNumber, index) => (
                            <option key={index} value={`${boothNumber.booth_number}`}>
                                {boothNumber.booth_number}
                            </option>
                        ))}
                    </select>
                    */}


                    <input type="submit" />
                </form>
                {
                    dataSent && (<p className="dataSent">Bedankt voor de reservering. We nemen zo snel mogelijk contact met u op</p>)
                }
            </div>
        </div>
    )
}

export default Reserveren;
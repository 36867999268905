import React, { useEffect, useState } from "react";
import images from "../assets/images";
import Logo from "../assets/waterinfodag-logo.webp";

const PresentatieCard = ({ sessie }) => {

    {/* Slider voor profielfoto's */ }
    const [currentIndex, setCurrentIndex] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [transitionEnabled, setTransitionEnabled] = useState(true);

    const speakers = sessie.speakers || [];

    // Maak een nieuwe array met clones voor een vloeiende overgang
    const extendedSpeakers = [
        speakers[speakers.length - 1], // Clone laatste afbeelding vooraan
        ...speakers,
        speakers[0], // Clone eerste afbeelding achteraan
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Wanneer de slider op de laatste (cloned) afbeelding komt, spring onzichtbaar terug naar de echte eerste
        if (currentIndex === extendedSpeakers.length - 1) {
            setTimeout(() => {
                setTransitionEnabled(false);
                setCurrentIndex(1);
            }, 500); // Na de overgang direct resetten
        }

        // Wanneer de slider op de eerste (cloned) afbeelding komt, spring onzichtbaar naar de echte laatste
        if (currentIndex === 0) {
            setTimeout(() => {
                setTransitionEnabled(false);
                setCurrentIndex(extendedSpeakers.length - 2);
            }, 500);
        }

        // Zet de animatie weer aan nadat hij uitgeschakeld was
        setTimeout(() => setTransitionEnabled(true), 50);
    }, [currentIndex]);

    const realIndex = currentIndex === 0
        ? speakers.length - 1
        : currentIndex === extendedSpeakers.length - 1
            ? 0
            : currentIndex - 1;

    const nextSlide = () => setCurrentIndex((prevIndex) => prevIndex + 1);
    const prevSlide = () => setCurrentIndex((prevIndex) => prevIndex - 1);

    const hasMultipleSpeakers = speakers.length > 1;
    const isSpecialSession = speakers.length === 2 && speakers.some(s => s.name === "Arwin de Beer");

    const shouldUseSlider = hasMultipleSpeakers && !isSpecialSession;

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="card">
            <div className="card__preview">

                <div
                    className="slider-wrapper"
                    style={{
                        transform: shouldUseSlider ? `translateX(-${currentIndex * 100}%)` : "none",
                        transition: shouldUseSlider && transitionEnabled ? "transform 0.5s ease-in-out" : "none",
                    }}
                >
                    {extendedSpeakers.map((speaker, index) => (
                        <img
                            key={index}
                            src={images[speaker?.image] || Logo}
                            alt={speaker?.name}
                        />
                    ))}
                </div>

                {shouldUseSlider && (
                    <div className="slider-dots">
                        {speakers.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === realIndex ? "active" : ""}`}
                                onClick={() => setCurrentIndex(index + 1)} // +1 om de juiste echte index te pakken
                            />
                        ))}
                    </div>
                )}

                <p className="card__date">{sessie.session_time} | {sessie.presentatie_vorm}</p>
            </div>
            <div className="card__content">
                <h3>{sessie.title}</h3>
                <div className="card__content__speakersCompany">
                    <p className="card__speaker">{sessie.speakers.map(speaker => speaker.name).join(', ')}</p>
                    <p className="card__company">{sessie.speakers.map(speaker => speaker.company).join(', ')}</p>
                </div>

                {sessie.target_audience !== "NaN" && (
                    <p>Doelgroep: {sessie.target_audience}</p>
                )}

            </div>
            <div className="card__bottom">
                <button
                    onClick={openModal}
                    className="card__btn"
                    >
                    Meer informatie
                </button>
            </div>

            {isModalOpen && (
                <div className="cardModal">
                    <div className="cardModal__content">
                        <h2>{sessie.title}</h2>
                        <div className="cardModal__description">
                            <p>{sessie.description}</p>
                        </div>
                        <p><strong>Sprekers:</strong> {sessie.speakers.map(speaker => speaker.name).join(', ')}</p>
                        <p><strong>Organisatie:</strong> {sessie.speakers.map(speaker => speaker.company).join(', ')}</p>
                        <p><strong>Tijd:</strong> {sessie.session_time}</p>
                        <p><strong>Zaal:</strong> {sessie.presentatie_vorm}</p>
                        <div>
                            <button
                                onClick={closeModal}
                                className="cardModal__close">
                                Sluiten
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}

export default PresentatieCard;
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Partners from "./Partners";

import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

const Root = () => {
    return (
        <>
            <ScrollRestoration />
            <Header />
            <Outlet />
            <Partners />
            <Footer />
        </>
    )
};

export default Root;
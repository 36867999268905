import React from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// import logo from './logo.svg';
// Componenten
import Root from "./components/Root";
import Home from "./pages/Home";
import Beurs from "./pages/Beurs";
import Prijzen from "./pages/Prijzen";
import Reserveren from "./pages/Reserveren";
import Deelname from "./pages/Deelname";
import Contact from "./pages/Contact";
import Streamtheater from "./pages/Streamtheater";
import Programma from "./pages/Programma";
import PresentatieVoorstel from "./pages/PresentatieVoorstel";
import Handboek from "./pages/Handboek";
import Winkel from "./pages/Winkel";
import StandhouderInschrijen from "./pages/StandhouderInschrijen";
import Live from "./pages/Live";

// fetch data helper
// import getPresentationData from "./helpers/FetchPresentationData";
// const data = getPresentationData();
// console.log(data);

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<Root />}>
    <Route index element={<Home />} />
    <Route path="beurs" element={<Beurs />} />
    <Route path="plattegrond" element={<Beurs />} />
    <Route path="deelname" element={<Deelname />} />
    <Route path="contact" element={<Contact />} />
    <Route path="prijzen-beurs" element={<Prijzen />} />
    <Route path="reserveren-beursplaats" element={<Reserveren />} />
    <Route path="newsletter" />
    <Route path="streamtheater" element={<Streamtheater />} />
    <Route path="programma" element={<Programma />}/>
    <Route path="voorstel-admin" element={<PresentatieVoorstel />}/>
    <Route path="beurs-handboek" element={<Handboek />}/>
    <Route path="winkel" element={<Winkel />}/>
    <Route path="inschrijven-standhouder" element={<StandhouderInschrijen />}/>
    <Route path="live" element={<Live />} />
  </Route>
));

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;

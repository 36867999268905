import React, { useState, useRef } from "react"
import Hero from "../components/Hero";
import { handleSubmit } from '../utils/formUtils';

const PresentatieVoorstel = () => {
    const [presentation, setPresentation] = useState({
        speakers: [],
        title: "",
        description: "",
        summary: "",
        topic: [],
        target_audience: [],
        specific_target_audience: "",
        presentation_form: "",
        interaction: "",
        comment: "",
        stream_timeslot: "",
    });

    const formRef = useRef(null);
    const [dataSent, setDataSent] = useState(false);
    const [errorWithSent, setErrorWithSent] = useState(false);


    const handleSubmitAddOn = (event) => {
        event.preventDefault();

        const form = event.target;
        let isValid = true;

        // Controleer elk vereist veld handmatig en extra controle voor andere velden.
        const requiredFields = form.querySelectorAll("[required]");
        requiredFields.forEach((field) => {
            if (!field.value.trim()) {
                field.setCustomValidity("Dit veld is verplicht.");
                isValid = false;

                // Voeg visuele feedback toe
                field.classList.add("error");
            } else {
                field.setCustomValidity("");
                field.classList.remove("error");
            }
        });

        if (presentation.speakers.length === 0) {
            alert("Voeg een spreker toe.");
            isValid = false;
        }

        // Valideer thema specifiek
        if (presentation.topic.length === 0) {
            alert("Vul een thema voor de presentatie in.");
            isValid = false;
        }

        if (isValid) {
            handleSubmit(event, presentation, "https://waterinfodag.nl/api/presentations", setPresentation, setDataSent, setErrorWithSent);
        } else {
            // Scroll naar het eerste foutieve veld
            form.querySelector(".error")?.focus();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPresentation((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSpeakerChange = (index, e) => {
        const { name, value } = e.target;
        const newSpeakers = [...presentation.speakers];
        newSpeakers[index] = {
            ...newSpeakers[index],
            [name]: value,
        };
        setPresentation((prevState) => ({
            ...prevState,
            speakers: newSpeakers,
        }));
    };

    const addSpeaker = () => {
        setPresentation((prevState) => ({
            ...prevState,
            speakers: [
                ...prevState.speakers,
                {
                    name: "",
                    company: "",
                    email: "",
                    phone: "",
                },
            ],
        }));
    };

    const removeSpeaker = (index) => {
        setPresentation((prevState) => ({
            ...prevState,
            speakers: prevState.speakers.filter((_, i) => i !== index),
        }));
    };

    const handleInputCheckedChange = (e) => {
        const { name, value, checked } = e.target;

        setPresentation((prevFormData) => {
            const updatedTopics = checked
                ? [...prevFormData[name], value]
                : prevFormData[name].filter((val) => val !== value);

            return {
                ...prevFormData,
                [name]: updatedTopics,
            };
        });
    }

    return (
        <div className="voorstel__container">
            <Hero heroTitle="Presentatie indienen" />
            <div className="voorstel__header">
                <h2>Laat data stromen als water</h2>
                <p>De inschrijvingstermijn voor programmavoorstellen is geopend tot en met 11 januari.</p>
                <p>Het thema van de Waterinfodag 2025 is: “Laat data stromen als water”.</p>
                <p>Geïnspireerd door dit thema kunnen voorstellen worden ingediend voor een van onderstaande subthema’s, of een combinatie er van:</p>
                <h3>Een betrouwbare basis</h3>
                <p>Over de Inwinning van data die beschikbaar gesteld wordt als betrouwbare data en passend is voor het beoogde gebruiksdoel. Wat is er nodig aan hardware, software Informatiesystemen en infrastructuur voor een betrouwbare basis? Informeer over ontwikkelingen visies en concrete resultaten. Over Inwinningsmethoden, standaarden en kwaliteitsbewaking.</p>

                <h3>Innovaties</h3>
                <p>Nieuwe producten, inzichten of visies over het gebruik van data en de informatievoorziening voor het hedendaagse en toekomstige waterbeheer.
                    <br />Waarover kan men enthousiast worden?</p>

                <h3>Passend gebruik</h3>
                <p>Met welke instrumenten en informatiesystemen laat u data stromen naar gebruikers. Wie zijn uw gebruikers en hoe bedient u deze?<br />
                    Kunnen gebruikers (o.a. waterbeheerders, beleidsmakers, bestuurders) overweg met de data die hen aangeboden worden. Organiseer hier een debat over. Deel uw ervaringen. Hoe verlopen de connecties tussen de informatiesector en gebruikers?</p>

                <h3>Connecties</h3>
                <p>Welke samenwerkingen zijn er aangegaan en nodig om de data in te winnen, beschikbaar te stellen en te gebruiken?<br />
                    Deel uw ervaringen, visies en wensen.<br />
                    Hoe werken de connecties in de praktijk?<br />
                    Welke connecties zijn er tussen de watersector en andere sectoren?<br />
                    Vertel over samenwerkingsprocessen/projecten of organiseer een debat/interactieve sessie.</p>

                <h3>Young Professionals programma</h3>
                <p>Een deel van het programma is gereserveerd voor Young Professionals. Dit zijn mensen van 20 – 35 jaar die werkzaam zijn in de watersector. Zij worden uitgenodigd om te komen presenteren over hun projecten, hun visies en hun ervaringen.  Zie in deze nieuwsbrief een uitgebreidere uitnodiging aan Young professionals om programmavoorstellen te doen.</p>
                <hr color="rgb(226, 226, 226)" />
                <p>Voorzie uw voorstel van een inspirerende titel en een oproepende samenvatting. Wij gebruiken die voor de communicatie over het programma.</p>
                <p>Naast presentatievoorstellen ramen wij tijd in het programma in voor actievere interactieve sessie. Organiseer een debat, een discussie of forum.</p>
                <p>Bedrijven die voorstellen indienen voor het themaprogramma wordt geadviseerd om dit samen met klanten te doen.</p>
                <hr color="rgb(226, 226, 226)" />
            </div>
            
            <div className="voorstel__form">
                <h3>Indienen</h3>
                <form ref={formRef} onSubmit={handleSubmitAddOn}>

                    {/* Knop om een nieuwe spreker toe te voegen */}
                    <button type="button" onClick={addSpeaker}>Voeg een spreker toe</button>

                    {/* Sprekers formuliervelden */}
                    {presentation.speakers.map((speaker, index) => (
                        <div key={index} className="speaker-fields">
                            <h4>Spreker {index + 1}</h4>
                            <label htmlFor={`speaker-name-${index}`}>Naam</label>
                            <input
                                type="text"
                                name="name"
                                id={`speaker-name-${index}`}
                                value={speaker.name}
                                onChange={(e) => handleSpeakerChange(index, e)}
                                required
                            />
                            <label htmlFor={`speaker-company-${index}`}>Organisatie</label>
                            <input
                                type="text"
                                name="company"
                                id={`speaker-company-${index}`}
                                value={speaker.company}
                                onChange={(e) => handleSpeakerChange(index, e)}
                                required
                            />
                            <label htmlFor={`speaker-email-${index}`}>E-mail</label>
                            <input
                                type="email"
                                name="email"
                                id={`speaker-email-${index}`}
                                value={speaker.email}
                                onChange={(e) => handleSpeakerChange(index, e)}
                            />
                            <label htmlFor={`speaker-phone-${index}`}>Telefoonnummer</label>
                            <input
                                type="text"
                                name="phone"
                                id={`speaker-phone-${index}`}
                                value={speaker.phone}
                                onChange={(e) => handleSpeakerChange(index, e)}
                            />
                            <button type="button" className="voostel__btn_remove" onClick={() => removeSpeaker(index)}>Verwijder spreker {index + 1}</button>
                            {/* Knop om een nieuwe spreker toe te voegen */}
                            <div >
                                <button type="button" onClick={addSpeaker}>Voeg spreker toe</button>
                            </div>
                        </div>
                    ))}

                    <label htmlFor="title">Presentatie titel</label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        value={presentation.title}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="description">Beschrijving</label>
                    <textarea
                        name="description"
                        id="description"
                        value={presentation.description}
                        onChange={handleInputChange}
                        required
                    />
                    <label htmlFor="summary">Samenvatting</label>
                    <input
                        type="text"
                        name="summary"
                        id="summary"
                        value={presentation.summary}
                        onChange={handleInputChange}
                        required
                    />
                    <hr color="rgb(226, 226, 226)" />
                    <p>Thema van de presentatie (meerdere antwoorden mogelijk)</p>
                    <label className="checkbox__container">
                        <input type="checkbox" name="topic" value="Een betrouwbare basis" onChange={handleInputCheckedChange} />
                        Een betrouwbare basis
                    </label>

                    <label className="checkbox__container">
                        <input type="checkbox" name="topic" value="Innovaties" onChange={handleInputCheckedChange} />
                        Innovaties</label>

                    <label className="checkbox__container">
                        <input type="checkbox" name="topic" value="Passend gebruik" onChange={handleInputCheckedChange} />
                        Passend gebruik</label>

                    <label className="checkbox__container">
                        <input type="checkbox" name="topic" value="Connecties" onChange={handleInputCheckedChange} />
                        Connecties</label>

                    <label className="checkbox__container">
                        <input type="checkbox" name="topic" value="Young Professionals programma" onChange={handleInputCheckedChange} />
                        Young Professionals programma</label>

                    <p>Doelgroep van de presentatie (meerdere antwoorden mogelijk)</p>
                    <label className="checkbox__container">
                        <input type="checkbox" name="target_audience" value="Informatiesector" onChange={handleInputCheckedChange} />
                        Informatiesector
                    </label>

                    <label className="checkbox__container">
                        <input type="checkbox" name="target_audience" value="Gebruikers" onChange={handleInputCheckedChange} />
                        Gebruikers</label>

                    <label className="checkbox__container">
                        <input type="checkbox" name="target_audience" value="Specifiek" onChange={handleInputCheckedChange} />
                        Specifiek</label>

                    {presentation.target_audience.includes("Specifiek") && (
                        <div>
                            <label htmlFor="specific_target_audience">Specificieer uw doelgroep</label>
                            <input
                                type="text"
                                name="specific_target_audience"
                                id="specific_target_audience"
                                value={presentation.specific_target_audience}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}

                    <p>Werkvorm
                        <label className="radio__container">
                            <input type="radio" name="presentation_form" value="Presentatie" onChange={handleInputChange} />
                            Presentatie</label>

                        <label className="radio__container">
                            <input type="radio" name="presentation_form" value="Interactief" onChange={handleInputChange} />
                            Interactief</label>

                    </p>

                    {presentation.presentation_form === "Interactief" && (
                        <div>
                            <label htmlFor="interaction">Welke interactieve vorm?</label>
                            <input
                                type="text"
                                name="interaction"
                                id="interaction"
                                value={presentation.interaction}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}

                    <label htmlFor="comment">Opmerking</label>
                    <input
                        type="text"
                        name="comment"
                        id="comment"
                        value={presentation.comment}
                        onChange={handleInputChange}
                    />

                    <input type="submit" />
                </form>
                
                {
                    dataSent && (<p className="dataSent">Bedankt voor de indiening. Eind januari worden alle voorstellen beoordeeld. U ontvangt in februari een bericht of uw presentatie is geplaatst.</p>)
                }

                {
                    errorWithSent && (<p className="dataNotSent">Helaas is er iets mis gegaan tijdens het verzenden. Heeft u alles goed ingevuld? Probeer het nog een keer of mail ons op info@waterinfodag.nl</p>)
                }

            </div>

        </div>
    )
}

export default PresentatieVoorstel;